// import BannerImg from "../../../content/assets/transparency-compliance-consent.svg"
import DatapointsImg from "../../../content/assets/winr-data-7-datapoints__light_2.png"

const homePageDataOld = {
  hero_banner: {
    heading: 'Global Identity Data Solutions',
    background_colour: '#071826',
    text_colour: '#fff',
    subheading: `<p>For businesses that are building their Identity Graphs or simply need to know the identities of their customers, WINR Data provides a single access point to digital and terrestrial data sets providing consistency and scale. At a time when Privacy and Compliance is at the core of risk management, our focus is unashamedly on Transparency, Compliance and&nbsp;Consent.</p>`,
    include_image_slider: true,
    slider_images: [
      {
        url: DatapointsImg,
        alt: 'Global Identity Data Solutions'
      }     
    ]    
  }
}

export { homePageDataOld }