import React from "react"
import { homePageDataOld } from "../components/Data/HomePageDataOld"
import styled, { createGlobalStyle } from "styled-components"
import { NavLink, NavExtLink } from "components/NavMenuDesktop"
import { LogoWrap } from "components/HeaderLogo"
import { UnderlineElement } from "components/Ui/Underline"
import Layout from "components/Layout"
import SeoComponent from "components/SeoComponent"
import HeroBanner from "components/HeroBanner"
import Leadins from "components/Leadins"

const HomepageTheme = createGlobalStyle`
  .headroom--unfixed {
    ${NavLink},
    ${NavExtLink} {
      transition: color 0.25s ease;
      
      &::after {
        background-color: #ffffff;
      }

      &:hover {
        color: #ffffff;
      }
    }

    ${UnderlineElement} {
      background-color: #ffffff;
    }    

    header nav.nav-desktop {
      color: #ffffff;

      a {
        color: #ffffff;

        &:focus {
          color: #ffffff;
        }
      }
    }
    .hamburger-inner, .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: #ffffff;
    }

    ${LogoWrap} {
      svg path {
        &.winr-data-logo_svg__c {
          fill: #ffffff;
        }      
      }
    }      
  }

  .headroom--pinned {
    ${LogoWrap} {
      svg path {
        &.winr-data-logo_svg__c {
          fill: #000000;
        }      
      }
    }
  }    
  
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #ffffff;
  }
`

const FeedWrapper = styled.section`
  background-color: #ffffff;
`

const HomePage = () => {
  const page = homePageDataOld
  // console.log(process.env.GATSBY_ACTIVE_ENV)
  // console.log(process.env.GATSBY_ENV)

  return (
    <Layout pageName="home">
      <SeoComponent
        title="Data Driven Identity Solutions for Data Owners &amp; Global Service Providers"
        description="For businesses that need to know the identities of their customers, WINR Data provides a single access point to our international data consortium."
      />
      <HomepageTheme />
      <FeedWrapper className="feed-wrapper">
        <div className="feed">
          {page.hero_banner && (
            <HeroBanner fields={page.hero_banner} pageName="home" />
          )}

          <Leadins pageName="home" />
        </div>
      </FeedWrapper>
    </Layout>
  )
}
export default HomePage
